<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <div>
        <v-icon>mdi-clock-time-one-outline</v-icon>
        <v-icon>mdi-swap-horizontal</v-icon>
      </div>
      Convert Time Unit
    </SecondaryButton>
    <ConvertTimeUnitDialog
      :isOpen="isOpen"
      @close="closeDialog"
      :timeUnit="timeUnit"
    />
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import BaseAction from "@/components/common/BaseAction";
import ConvertTimeUnitDialog from "@/views/bookings/components/ConfirmDialogs/ConvertTimeUnitDialog.vue";

export default {
  name: "ConvertTimeUnitAction",
  components: {
    BaseAction,
    ConvertTimeUnitDialog,
    SecondaryButton
  },
  props: {
    timeUnit: String
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
