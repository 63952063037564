/* eslint-disable prettier/prettier */
import { addGlobalMessage, handleWithErrorMessage, handleWithGlobalMessage, handleWithMessageAndLoading, errorHandler } from '@/services/utils';
import { last, map, pick } from 'lodash';
import api from '@/services/modules/bookings';
import {
  SET_BOOKING_PAYRATES,
  SET_BOOKING_SHIFTS,
  SET_BOOKINGS,
  SET_CURRENT_BOOKING,
  SET_CURRENT_BOOKING_CANDIDATES,
  SET_CANDIDATES_FOR_SUBMIT,
  SET_CURRENT_BOOKING_COMMENTS,
  SET_CURRENT_BOOKING_EVENTS,
  SET_CURRENT_BOOKING_PUBLISHING_SCHEDULE,
  SET_CURRENT_BOOKING_SHIFT_PATTERNS,
  SET_IS_EDITING_BOOKING_PAYRATES,
  SET_IS_FETCHING_BOOKING_PAYRATES,
  SET_IS_FETCHING_BOOKING_SHIFTS,
  SET_IS_FETCHING_BOOKINGS,
  SET_IS_FETCHING_CURRENT_BOOKING,
  SET_IS_FETCHING_CURRENT_BOOKING_CANDIDATES,
  SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT,
  SET_IS_FETCHING_CURRENT_BOOKING_COMMENTS,
  SET_IS_FETCHING_CURRENT_BOOKING_EVENTS,
  SET_IS_FETCHING_CURRENT_BOOKING_PUBLISHING_SCHEDULE,
  SET_IS_FETCHING_CURRENT_BOOKING_SHIFT_PATTERNS,
  SET_TOTAL_BOOKING_SHIFTS,
  SET_BOOKINGS_TO_APPROVE,
  SET_BOOKINGS_TO_ACCEPT,
  SET_BOOKINGS_WITH_SHIFTS_TO_APPROVE,
  SET_BUDGETS,
  SET_SHIFTS,
  SET_IS_FETCHING_SHIFTS,
  SET_BOOKING_EXTENSIONS_TO_APPROVE
} from "@/store/modules/bookings/mutations";
import { GET_BOOKINGS, GET_CURRENT_BOOKING, GET_CURRENT_BOOKING_COMMENTS } from '@/store/modules/bookings/getters';
import { applicationWithoutShiftIncludes } from "@/services/modules/bookings";
import {
  include,
  USER
} from "@/models/relationships";

export const SUBMIT_BOOKING_FOR_APPROVAL = "SUBMIT_BOOKING_FOR_APPROVAL";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const APPROVE_BOOKING = "APPROVE_BOOKING";
export const ACCEPT_BOOKING = "ACCEPT_BOOKING";
export const PUBLISH_BOOKING = "PUBLISH_BOOKING";
export const EDIT_BOOKING = "EDIT_BOOKING";
export const EDIT_BOOKING_WITH_MESSAGE = "EDIT_BOOKING_WITH_MESSAGE";
export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const REJECT_BOOKING = "REJECT_BOOKING";
export const FETCH_CURRENT_BOOKING = "FETCH_CURRENT_BOOKING";
export const FETCH_CURRENT_BOOKING_COMMENTS = "FETCH_CURRENT_BOOKING_COMMENTS";
export const FETCH_CURRENT_BOOKING_CANDIDATES = "FETCH_CURRENT_BOOKING_CANDIDATES";
export const FETCH_BOOKING_CANDIDATES_FOR_SUBMIT = "FETCH_BOOKING_CANDIDATES_FOR_SUBMIT";
export const FETCH_CURRENT_BOOKING_SHIFT_PATTERNS = "FETCH_CURRENT_BOOKING_SHIFT_PATTERNS";
export const FETCH_CURRENT_BOOKING_EVENTS = "FETCH_CURRENT_BOOKING_EVENTS";
export const FETCH_CURRENT_BOOKING_PUBLISHING_SCHEDULE = "FETCH_CURRENT_BOOKING_PUBLISHING_SCHEDULE";
export const FETCH_BOOKING_SHIFTS = "FETCH_BOOKING_SHIFTS";
export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const CREATE_BOOKING = "CREATE_BOOKING";
export const ADD_COMMENT = "ADD_COMMENT";
export const FETCH_BOOKING_PAYRATES = "FETCH_BOOKING_PAYRATES";
export const CREATE_SHIFT_PATTERN = "CREATE_SHIFT_PATTERN";
export const DELETE_SHIFT_PATTERNS = "DELETE_SHIFT_PATTERNS";
export const EDIT_BOOKING_PAYRATES = "EDIT_BOOKING_PAYRATES";
export const CANCEL_SHIFT = "CANCEL_SHIFT";
export const CANCEL_SHIFTS = "CANCEL_SHIFTS";
export const EDIT_APROVER_VERIFIER= "EDIT_APROVER_VERIFIER";
export const EDIT_PO_NUMBER= "EDIT_PO_NUMBER";
export const SUBMIT_SHIFT_PATTERN = "SUBMIT_SHIFT_PATTERN";
export const REJECT_SHIFT_PATTERN = "REJECT_SHIFT_PATTERN";
export const APPROVE_SHIFT_PATTERN = "APPROVE_SHIFT_PATTERN";
export const DELETE_SHIFT_PATTERN = "DELETE_SHIFT_PATTERN";
export const ADD_BOOKING_FILE = "ADD_BOOKING_FILE";
export const FETCH_BOOKINGS_WITH_ACTION = "FETCH_BOOKINGS_WITH_ACTION";
export const FETCH_BUDGETS = "FETCH_BUDGETS";
export const CREATE_BUDGET = "CREATE_BUDGET";
export const UPDATE_BUDGET = "UPDATE_BUDGET";
export const CLEAR_BUDGET = "CLEAR_BUDGET";
export const UPDATE_QUOTA = "UPDATE_QUOTA";
export const CREATE_QUOTA = "CREATE_QUOTA";
export const DELETE_QUOTA = "DELETE_QUOTA";
export const FETCH_SHIFTS = "FETCH_SHIFTS";
export const PRINTABLE_TIMESHEET = "PRINTABLE_TIMESHEET";
export const UPDATE_SHIFT = "UPDATE_SHIFT";
export const SUBMIT_AND_CONFIRM_WORKERS = "SUBMIT_AND_CONFIRM_WORKERS";
export const REOPEN_BOOKING = "REOPEN_BOOKING";
export const CREATE_BULK_BOOKINGS = "CREATE_BULK_BOOKINGS";
export const FETCH_BOOKING_SHIFTS_V2 = "FETCH_BOOKING_SHIFTS_V2";
export const EDIT_TIME_UNIT= "EDIT_TIME_UNIT";

export const BOOKINGS_NAMESPACE = "bookings";

const bookingsActions = {
  async [SUBMIT_BOOKING_FOR_APPROVAL]({ dispatch, commit }, bookingId) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).submitForApproval(bookingId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [DELETE_BOOKING]({ dispatch, commit, getters }, bookingId) {
    await handleWithGlobalMessage(
      () => api({ commit }).deleteBooking(bookingId),
      dispatch
    );
    const currentBookings = getters[GET_BOOKINGS];
    const newBookings = currentBookings.filter(
      booking => booking.id !== bookingId
    );
    commit(SET_CURRENT_BOOKING, null);
    commit(SET_BOOKINGS, newBookings);
  },
  async [APPROVE_BOOKING]({ dispatch, commit }, bookingId) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).approveBooking(bookingId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [ACCEPT_BOOKING]({ dispatch, commit }, bookingId) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).acceptBooking(bookingId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [EDIT_BOOKING](
    { dispatch, commit, getters },
    { bookingId, editedBooking }
  ) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).editBooking(bookingId, editedBooking),
      dispatch
    );
    const newBooking = {
      ...getters[GET_CURRENT_BOOKING],
      ...pick(booking, Object.keys(editedBooking))
    };
    commit(SET_CURRENT_BOOKING, newBooking);
  },
  async [EDIT_APROVER_VERIFIER](
    { dispatch, commit, getters },
    { bookingId, editedApprover, editedVerifier }
  ) {
    try {
      await api({ commit }).editBookingTimesheetApprover(bookingId, editedApprover);
      const { data: booking, meta } = await api({ commit }).editBookingTimesheetVerifier(bookingId, editedVerifier);
      editedVerifier.approver = editedApprover.approver;
      const newBooking = {
        ...getters[GET_CURRENT_BOOKING],
        ...pick(booking, Object.keys(editedVerifier))
      };
      commit(SET_CURRENT_BOOKING, newBooking);
      addGlobalMessage(dispatch, { type: "success", meta });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [EDIT_PO_NUMBER](
    { dispatch, commit, getters },
    { bookingId, data }
  ) {
    try {
      const { data: booking, meta } = await api({ commit }).editBookingPONumber(bookingId, data);
      const newBooking = {
        ...getters[GET_CURRENT_BOOKING],
        ...pick(booking, Object.keys(data))
      };
      commit(SET_CURRENT_BOOKING, newBooking);
      addGlobalMessage(dispatch, { type: "success", meta });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [EDIT_BOOKING_WITH_MESSAGE](
    { dispatch, commit, getters },
    { bookingId, editedBooking }
  ) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).editBooking(bookingId, editedBooking),
      dispatch
    );
    const newBooking = {
      ...getters[GET_CURRENT_BOOKING],
      ...pick(booking, Object.keys(editedBooking))
    };
    commit(SET_CURRENT_BOOKING, newBooking);
  },
  async [PUBLISH_BOOKING]({ dispatch, commit }, { bookingId, unitId }) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).publishBooking(bookingId, unitId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [CANCEL_BOOKING]({ dispatch, commit }, bookingId) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).cancelBooking(bookingId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [REJECT_BOOKING]({ dispatch, commit }, bookingId) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).rejectBooking(bookingId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [REOPEN_BOOKING]({ dispatch, commit }, bookingId) {
    const { data: booking } = await handleWithGlobalMessage(
      () => api({ commit }).reopenBooking(bookingId),
      dispatch
    );
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [CANCEL_SHIFT]({ dispatch, commit }, { bookingId, shiftId }) {
    return  handleWithGlobalMessage(
      () => api({ commit }).cancelShift({ bookingId, shiftId }),
      dispatch
    )
  },
  async [CANCEL_SHIFTS]({ dispatch, commit }, { bookingId, shiftIds }) {
    return  handleWithGlobalMessage(
      () => api({ commit }).cancelShifts({ bookingId, shiftIds }),
      dispatch
    )
  },
  async [CREATE_BOOKING]({ dispatch, commit }, templateId) {
    try {
      return await api({ commit }).createBooking(templateId);
    } catch (e) {
      addGlobalMessage(dispatch, {
        type: "error",
        meta: { title: e.message }
      });
    }
  },
  async [CREATE_SHIFT_PATTERN]({ dispatch, commit, getters }, shiftData) {
    const { data: createdShiftPattern } = await handleWithErrorMessage({
      request: () => api({ commit }).createShift(shiftData),
      dispatch
    });

    const booking = { ...getters[GET_CURRENT_BOOKING] };
    booking.shiftPatterns = [
      ...(booking.shiftPatterns || []),
      createdShiftPattern
    ];
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [FETCH_CURRENT_BOOKING]({ dispatch, commit, state }, bookingId) {
    if (!bookingId) {
      bookingId = state.currentBooking.id;
    }
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBooking(bookingId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_BOOKING, isLoading),
      setDataFunction: ({ data }) => commit(SET_CURRENT_BOOKING, data)
    });
  },
  async [FETCH_BOOKING_PAYRATES]({ dispatch, commit }, { bookingId, params }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingPayRates(bookingId, params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKING_PAYRATES, isLoading),
      setDataFunction: ({ data }) => commit(SET_BOOKING_PAYRATES, data)
    });
  },
  async [EDIT_BOOKING_PAYRATES](
    { dispatch, commit },
    { bookingId, editedPayRates }
  ) {
    return handleWithMessageAndLoading({
      dispatch,
      request: () =>
        api({ commit }).editBookingPayRates(bookingId, editedPayRates),
      setLoadingFunction: isLoading =>
        commit(SET_IS_EDITING_BOOKING_PAYRATES, isLoading)
    });
  },
  async [FETCH_CURRENT_BOOKING_COMMENTS]({ dispatch, commit }, bookingId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingComments(bookingId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_BOOKING_COMMENTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_CURRENT_BOOKING_COMMENTS, data)
    });
  },
  async [FETCH_CURRENT_BOOKING_SHIFT_PATTERNS](
    { dispatch, commit },
    bookingId
  ) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingShiftPatterns(bookingId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_BOOKING_SHIFT_PATTERNS, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CURRENT_BOOKING_SHIFT_PATTERNS, data)
    });
  },
  async [ADD_COMMENT](
    { dispatch, commit, getters, rootState },
    { bookingId, comment }
  ) {
    const currentComments = getters[GET_CURRENT_BOOKING_COMMENTS];
    const author = rootState.auth.userData;
    const lastComment = last(currentComments);
    const newComments = [
      ...currentComments,
      {
        id: lastComment ? parseInt(lastComment.id, 10) + 1 : 1,
        type: "comments",
        body: comment.body,
        createdAt: new Date(),
        author
      }
    ];

    commit(SET_CURRENT_BOOKING_COMMENTS, newComments);
    try {
      await handleWithErrorMessage({
        dispatch,
        request: () => api({ commit }).addBookingComment(bookingId, comment),
        setLoadingFunction: isLoading =>
          commit(SET_IS_FETCHING_CURRENT_BOOKING_COMMENTS, isLoading)
      });
      await dispatch[FETCH_CURRENT_BOOKING_COMMENTS];
    } catch (e) {
      commit(SET_CURRENT_BOOKING_COMMENTS, currentComments);
      throw e;
    }
  },
  async [FETCH_CURRENT_BOOKING_CANDIDATES](
    { dispatch, commit, state },
    bookingId
  ) {
    if (!bookingId) {
      bookingId = state.currentBooking.id;
    }
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingCandidates({ bookingId, includes: applicationWithoutShiftIncludes }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_BOOKING_CANDIDATES, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CURRENT_BOOKING_CANDIDATES, data)
    });
  },
  async [FETCH_BOOKING_CANDIDATES_FOR_SUBMIT](
    { dispatch, commit },
    { bookingId, params }
  ) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingCandidates({ bookingId, params, includes: include(USER) }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CANDIDATES_FOR_SUBMIT, data)
    });
  },
  async [FETCH_CURRENT_BOOKING_EVENTS]({ dispatch, commit }, bookingId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingEvents(bookingId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_BOOKING_EVENTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_CURRENT_BOOKING_EVENTS, data)
    });
  },
  async [FETCH_CURRENT_BOOKING_PUBLISHING_SCHEDULE](
    { dispatch, commit },
    bookingId
  ) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingPublishingSchedule(bookingId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_BOOKING_PUBLISHING_SCHEDULE, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CURRENT_BOOKING_PUBLISHING_SCHEDULE, data)
    });
  },
  async [FETCH_BOOKING_SHIFTS]({ dispatch, commit }, { id, params }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingShifts({ id, params }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKING_SHIFTS, isLoading),
      setDataFunction: ({ data, meta }) => {
        const { page } = meta;
        if (page) {
          commit(SET_TOTAL_BOOKING_SHIFTS, page.total)
        }
        commit(SET_BOOKING_SHIFTS, data)
      }
    });
  },
  async [FETCH_BOOKING_SHIFTS_V2]({ dispatch, commit }, { id, params }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookingShiftsV2({ id, params }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKING_SHIFTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_BOOKING_SHIFTS, data)
    });
  },
  async [FETCH_BOOKINGS]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookings(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKINGS, isLoading),
      setDataFunction: ({ data }) => commit(SET_BOOKINGS, data)
    });
  },
  async [FETCH_BOOKINGS_WITH_ACTION]({ dispatch, commit }, { type, root_client_id }) {
    let mutation = SET_BOOKINGS_TO_APPROVE;
    let params = { filter: { to_approve: 1, root_client_id } };
    if (type === "accept") {
      params =  { filter: { to_accept: true, root_client_id } };
      mutation = SET_BOOKINGS_TO_ACCEPT;
    } else if (type === "shifts") {
      params = { filter: { with_shifts_to_approve: true, root_client_id }};
      mutation = SET_BOOKINGS_WITH_SHIFTS_TO_APPROVE;
    } else if (type === "extensions") {
      params = { filter: { extensions_to_approve: true, root_client_id }};
      mutation = SET_BOOKING_EXTENSIONS_TO_APPROVE;
    }
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBookings(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKINGS, isLoading),
      setDataFunction: ({ data }) => commit(mutation, data)
    });
  },
  async [DELETE_SHIFT_PATTERNS]({ dispatch, commit, getters }, bookingId) {
    await handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).deleteShiftPatterns(bookingId)
    });

    const booking = { ...getters[GET_CURRENT_BOOKING] };
    booking.shiftPatterns = [];
    commit(SET_CURRENT_BOOKING, booking);
  },
  async [SUBMIT_SHIFT_PATTERN]({ dispatch, commit }, { id, bookingId }) {
    await handleWithGlobalMessage(
      () => api({ commit }).submitShiftPattern(id),
      dispatch
    );
    dispatch(FETCH_CURRENT_BOOKING_SHIFT_PATTERNS, bookingId);
  },
  async [REJECT_SHIFT_PATTERN]({ dispatch, commit }, { id, bookingId }) {
    await handleWithGlobalMessage(
      () => api({ commit }).rejectShiftPattern(id),
      dispatch
    );
    dispatch(FETCH_CURRENT_BOOKING_SHIFT_PATTERNS, bookingId);
  },
  async [APPROVE_SHIFT_PATTERN]({ dispatch, commit }, { id, bookingId }) {
    await handleWithGlobalMessage(
      () => api({ commit }).approveShiftPattern(id),
      dispatch
    );
    dispatch(FETCH_CURRENT_BOOKING_SHIFT_PATTERNS, bookingId);
  },
  async [DELETE_SHIFT_PATTERN]({ dispatch, commit }, { id, bookingId }) {
    await handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).deleteShiftPattern(id),
    });
    if (bookingId) {
      dispatch(FETCH_CURRENT_BOOKING_SHIFT_PATTERNS, bookingId);
    }    
  },
  async [ADD_BOOKING_FILE]({ dispatch, commit }, { id, data }) {
    return handleWithErrorMessage({
      request: () => api({ commit }).createBookingFile(id, data),
      dispatch
    });
  },
  async [FETCH_BUDGETS]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getBudgets(params),
      setDataFunction: ({ data }) => commit(SET_BUDGETS, data)
    });
  },
  async [CREATE_BUDGET]({ dispatch, commit }, data) {
    await handleWithGlobalMessage(
      () => api({ commit }).createBudget(data),
      dispatch
    );
  },
  async [UPDATE_BUDGET]({ dispatch, commit }, data) {
    await handleWithGlobalMessage(
      () => api({ commit }).editBudget(data),
      dispatch
    );
  },
  async [CLEAR_BUDGET]({  commit }) {
    commit(SET_BUDGETS, []);
  },
  async [CREATE_QUOTA]({ dispatch, commit }, { scheduleId, data }) {
    await handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).createQuota({ scheduleId, data })
    });
  },
  async [UPDATE_QUOTA]({ dispatch, commit }, { scheduleId, data }) {
    await handleWithErrorMessage({
      request: () => api({ commit }).editQuota({ scheduleId, data }),
      dispatch
    });
  },
  async [DELETE_QUOTA]({ dispatch, commit }, { bookingId, scheduleId, quotaId }) {
    await handleWithErrorMessage({
      request: () => api({ commit }).deleteQuota({ bookingId, scheduleId, quotaId }),
      dispatch
    });
  },
  async [FETCH_SHIFTS]({ dispatch, commit }, params = {}) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getShifts(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_SHIFTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_SHIFTS, data)
    });
  },
  async [PRINTABLE_TIMESHEET]({dispatch, commit}, { shifts }) {
    await handleWithErrorMessage({
      request: () =>  api({ commit }).printableTimesheetPdf({ shifts }),
      dispatch
    });
  },
  async [UPDATE_SHIFT]({ dispatch, commit }, data) {
    await handleWithErrorMessage({
      request: () => api({ commit }).editShift(data),
      dispatch
    });
  },
  async [SUBMIT_AND_CONFIRM_WORKERS]({ dispatch, commit }, { bookingId, shiftId, workers }) {
    return await Promise.all(map(workers, async worker => {
      await handleWithGlobalMessage(
        () => api({ commit }).submitAndConfirmWorker({ bookingId, shiftId, worker }),
        dispatch
      );
    }));
  },
  async [CREATE_BULK_BOOKINGS]({ dispatch, commit }, { data }) {
    return await handleWithErrorMessage({
        request: () => api({ commit }).createBulk({ data }),
      dispatch
    });
  },

  async [EDIT_TIME_UNIT](
    { dispatch, commit },
    { bookingId, data }
  ) {
    try {
      const { data: booking, meta } = await api({ commit }).editTimeUnit(bookingId, data);
      commit(SET_CURRENT_BOOKING, booking);
      addGlobalMessage(dispatch, { type: "success", meta });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
};

export default bookingsActions;
